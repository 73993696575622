import { Divider, Image } from "antd";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div style={{ marginBottom: 60 }}>
      <Divider />
      <div style={{ justifyContent: "left", alignItems: "left", display: "flex" }}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/info">Bond Info</Link>
          </li>
          <li>
            <Link to="/bonds">Bonds</Link>
          </li>
          <li>
            <Link to="/marketplace">Marketplace</Link>
          </li>
          <li>
            <Link to="readme">README</Link>
          </li>
        </ul>
      </div>
      <div>
        A J.O.N. Creation <br />
        <p style={{ fontSize: 8 }}>Int. MIT licensing where applicable</p>
      </div>
      <h4>Thank you for visiting!</h4>
      <p>Email the Developer: email@jonthedev.com</p>
      <p>This site does not contain 3rd party trackers. We do not collect your personal information.</p>
      <div>
        <br />
        Version: 1.0.3 (last updated: 11:01 AM , April 21st, 2023 Eastern Standard Time (EST))
        <br />
        Soli Deo Gloria
      </div>
    </div>
  );
}

export default Footer;
