import React, { useState } from "react";
import { Button, Card, Input, Select } from "antd";
import { useContractLoader, useUserProviderAndSigner } from "eth-hooks";
import { Table } from "antd";
import { bondDetails } from "../constants/BondDetails";
// import FixedRateBond from "./contracts/FixedRateBond.sol";
// import CallableBond from "./contracts/CallableBond.sol";
// import ConvertibleBond from "./contracts/ConvertibleBond.sol";
// import ZeroCouponBond from "./contracts/ZeroCouponBond.sol";
// import ERC20 from "./contracts/ERC20.sol";
// import ERC721 from "./contracts/ERC721.sol";
// import ERC1155 from "./contracts/ERC1155.sol";

export default function Bonds({ localProvider, mainnetProvider, injectedProvider, address }) {
  const contracts = useContractLoader(localProvider);
  const userProvider = useUserProviderAndSigner(injectedProvider, localProvider);
  const [selectedToken, setSelectedToken] = useState("Fixed-Rate-721");
  const [tokenURI, setTokenURI] = useState("");
  const [amount, setAmount] = useState(1);
  const [tokenAddress, setTokenAddress] = useState();
  const [faceValue, setFaceValue] = useState();
  const [issuePrice, setIssuePrice] = useState();
  const [interestRate, setInterestRate] = useState();
  const [maturity, setMaturity] = useState();
  const [issuer, setIssuer] = useState();
  const [underlyingAsset, setUnderlyingAsset] = useState();
  const [triggerEvent, setTriggerEvent] = useState();
  const [redemptionAmount, setRedemptionAmount] = useState();
  const [redemptionFee, setRedemptionFee] = useState();
  const [couponRate, setCouponRate] = useState();
  const [spread, setSpread] = useState();
  const [referenceRate, setReferenceRate] = useState();
  const [conversionAsset, setConversionAsset] = useState();
  const [conversionRate, setConversionRate] = useState();
  const [referenceAsset, setReferenceAsset] = useState();

  const columns = [
    {
      title: "Bond",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Face Value",
      dataIndex: "faceValue",
      key: "faceValue",
      render: faceValue => `$${faceValue}`
    },
    {
      title: "Interest Rate",
      dataIndex: "interestRate",
      key: "interestRate",
      render: interestRate => `${interestRate}%`,
    },
    {
      title: "Maturity",
      dataIndex: "maturity",
      key: "maturity",
    },
    {
      title: "Minimum Investment",
      dataIndex: "issuePrice",
      key: "minInvestment",
      render: issuePrice => `$${issuePrice}`,
    },
    {
      title: "Total Raised",
      dataIndex: "totalRaised",
      key: "totalRaised",
      render: faceValue => `$${faceValue ? faceValue.toLocaleString() : 0}`,
    },
  ];

  const mintBond = async () => {
    if (contracts && address) {
      const signer = userProvider.getSigner();
      let contract;

      if (selectedToken === "Fixed-Rate-721") {
        contract = contracts.BondERC721.connect(signer);
        await contract.mint(address, tokenURI);
      } else if (selectedToken === "Fixed-Rate-1155") {
        contract = contracts.BondERC1155.connect(signer);
        await contract.mint(address, amount);
      }
    }
  };

  return (
    <div className="bond-page-container">
      <Table dataSource={bondDetails} columns={columns} rowKey="id" />
      <Card>
        <div className="bonds-actions">
          <Select value={selectedToken} onChange={value => setSelectedToken(value)}>
            <Select.Option value="Fixed-Rate-721">Fixed Rate ERC721 Bond</Select.Option>
            <Select.Option value="Fixed-Rate-1155">Fixed Rate ERC1155 Bond</Select.Option>
            <Select.Option value="Floating-Rate">Floating-Rate Bond</Select.Option>
            <Select.Option value="Callable">Callable Bond</Select.Option>
            <Select.Option value="Convertible">Convertible Bond</Select.Option>
            <Select.Option value="Zero-Coupon">Zero-Coupon Bond</Select.Option>
            <Select.Option value="Credit-Linked">Credit-Linked Note</Select.Option>
            <Select.Option value="Perpetual">Perpetual Bond</Select.Option>
          </Select>
        </div>
        <div className="bonds-parameters">
          {selectedToken === "Fixed-Rate-721" && (
            <div>
              <Input
                placeholder="Token URI (e.g. https://example.com/api/bond/1.json)"
                onChange={e => setTokenURI(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Face Value"
                type="number"
                min="1"
                onChange={e => setFaceValue(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Issue Price"
                type="number"
                min="1"
                onChange={e => setIssuePrice(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Coupon Rate (%)"
                type="number"
                min="0"
                step="0.01"
                onChange={e => setCouponRate(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Maturity (Unix Timestamp)"
                type="number"
                min="1"
                onChange={e => setMaturity(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
            </div>
          )}
          {selectedToken === "Fixed-Rate-1155" && (
            <div>
              <Input
                placeholder="Token URI (e.g. https://example.com/api/bond/1.json)"
                onChange={e => setTokenURI(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Face Value"
                type="number"
                min="1"
                onChange={e => setFaceValue(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Issue Price"
                type="number"
                min="1"
                onChange={e => setIssuePrice(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Coupon Rate (%)"
                type="number"
                min="0"
                step="0.01"
                onChange={e => setCouponRate(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Maturity (Unix Timestamp)"
                type="number"
                min="1"
                onChange={e => setMaturity(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
            </div>
          )}
          {selectedToken === "Floating-Rate" && (
            <div>
              <Input
                placeholder="Token URI (e.g. https://example.com/api/bond/1.json)"
                onChange={e => setTokenURI(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Face Value"
                type="number"
                min="1"
                onChange={e => setFaceValue(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Issue Price"
                type="number"
                min="1"
                onChange={e => setIssuePrice(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Reference Rate (%)"
                type="number"
                min="0"
                step="0.01"
                onChange={e => setReferenceRate(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Spread (%)"
                type="number"
                min="0"
                step="0.01"
                onChange={e => setSpread(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Maturity (Unix Timestamp)"
                type="number"
                min="1"
                onChange={e => setMaturity(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
            </div>
          )}
          {selectedToken === "Callable" && (
            <div>
              <Input
                placeholder="Token URI (e.g. https://example.com/api/bond/1.json)"
                onChange={e => setTokenURI(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Face Value"
                type="number"
                min="1"
                onChange={e => setFaceValue(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Issue Price"
                type="number"
                min="1"
                onChange={e => setIssuePrice(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Coupon Rate (%)"
                type="number"
                min="0"
                step="0.01"
                onChange={e => setCouponRate(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Maturity (Unix Timestamp)"
                type="number"
                min="1"
                onChange={e => setMaturity(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Redemption Fee"
                type="number"
                min="0"
                onChange={e => setRedemptionFee(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
            </div>
          )}
          {selectedToken === "Convertible" && (
            <div>
              <Input
                placeholder="Token URI (e.g. https://example.com/api/bond/1.json)"
                onChange={e => setTokenURI(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Face Value"
                type="number"
                min="1"
                onChange={e => setFaceValue(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Issue Price"
                type="number"
                min="1"
                onChange={e => setIssuePrice(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Interest Rate (%)"
                type="number"
                min="0"
                step="0.01"
                onChange={e => setInterestRate(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Conversion Asset Address"
                onChange={e => setConversionAsset(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Conversion Rate"
                type="number"
                min="0"
                step="0.0001"
                onChange={e => setConversionRate(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Maturity (Unix Timestamp)"
                type="number"
                min="1"
                onChange={e => setMaturity(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
            </div>
          )}
          {selectedToken === "Zero-Coupon" && (
            <div>
              <Input
                placeholder="Token URI (e.g. https://example.com/api/bond/1.json)"
                onChange={e => setTokenURI(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Face Value"
                type="number"
                min="1"
                onChange={e => setFaceValue(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Issue Price"
                type="number"
                min="1"
                onChange={e => setIssuePrice(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Interest Rate (%)"
                type="number"
                min="0"
                step="0.01"
                onChange={e => setInterestRate(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Maturity (Unix Timestamp)"
                type="number"
                min="1"
                onChange={e => setMaturity(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
            </div>
          )}
          {selectedToken === "Credit-Linked" && (
            <div>
              <Input
                placeholder="Token URI (e.g. https://example.com/api/bond/1.json)"
                onChange={e => setTokenURI(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Face Value"
                type="number"
                min="1"
                onChange={e => setFaceValue(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Issue Price"
                type="number"
                min="1"
                onChange={e => setIssuePrice(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Reference Asset Address"
                onChange={e => setReferenceAsset(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Maturity (Unix Timestamp)"
                type="number"
                min="1"
                onChange={e => setMaturity(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
            </div>
          )}
          {selectedToken === "Perpetual" && (
            <div>
              <Input
                placeholder="Token URI (e.g. https://example.com/api/bond/1.json)"
                onChange={e => setTokenURI(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Face Value"
                type="number"
                min="1"
                onChange={e => setFaceValue(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Issue Price"
                type="number"
                min="1"
                onChange={e => setIssuePrice(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Interest Rate (%)"
                type="number"
                min="0"
                step="0.01"
                onChange={e => setInterestRate(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
              <Input
                placeholder="Redemption Fee"
                type="number"
                min="0"
                onChange={e => setRedemptionFee(e.target.value)}
                style={{ width: "100%", marginTop: 8 }}
              />
            </div>
          )}
        </div>
      </Card>
      <div className="bonds-button">
        <Button
          type="primary"
          onClick={mintBond}
          style={{ marginTop: 8, maxWidth: 100, backgroundColor: "forestgreen" }}
        >
          Mint Bond
        </Button>
      </div>
    </div>
  );
}
