import { Image } from "antd";
import { Link } from "react-router-dom";
import matrix from "../assets/matrix.jpeg";
const UnkownPage = () => {
  return (
    <div>
      <Image preview={false} src={matrix} style={{ marginTop: 50, marginBottom: 50 }} />
      <h1>Page not found! You must go back!</h1>
      <Link to="/">Home</Link>
    </div>
  );
};
export default UnkownPage;
