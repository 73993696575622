import React from "react";
import { Card, Divider, Typography } from "antd";
import "../themes/BondInfo.css";

const { Title, Paragraph } = Typography;

const InfoPage = () => {
  return (
    <div className="info-container">
      <Title level={2}>Bonds Overview</Title>
        <Card>
      <div className="over-view">
        <Paragraph>
          Bonds are a type of fixed-income investment that offer a fixed or variable rate of return, depending on the
          type of bond. They are generally considered less risky than stocks and are a popular choice for both retail
          and institutional investors. In this section, we will provide an overview of some of the most common types of
          bonds in the market that are available on this platform.
        </Paragraph>
      </div>
        </Card>
      <Divider />
      <Card title="Fixed-Rate Bonds" className="bond-card">
        <Paragraph>
          Fixed-rate bonds pay a fixed rate of interest throughout the life of the bond. This means that the bondholder
          will receive a regular income stream, regardless of changes in market interest rates. Fixed-rate bonds are
          typically issued with a maturity date, at which point the bondholder will receive the face value of the bond.
        </Paragraph>
        <Divider />
        <Paragraph>
          Fixed-rate bonds are popular among investors who are looking for a stable income stream, as the fixed interest
          rate provides a predictable return. These bonds are generally considered less risky than floating-rate bonds,
          as the interest rate is not subject to market fluctuations.
        </Paragraph>
      </Card>
      <Divider />
      <Card title="Callable Bonds" className="bond-card">
        <Paragraph>
          Callable bonds allow the issuer to redeem the bond early under certain conditions. This means that the
          bondholder may not receive the full interest payments that they were expecting if the bond is called. Callable
          bonds are generally issued with a higher interest rate than non-callable bonds, to compensate for the
          increased risk.
        </Paragraph>
        <Divider />
        <Paragraph>
          Callable bonds are popular among issuers who want to retain the option to redeem the bond early if interest
          rates fall, as it allows them to refinance at a lower rate. However, callable bonds can be riskier for
          investors, as they may not receive the full return that they were expecting if the bond is called.
        </Paragraph>
      </Card>
      <Divider />
      <Card title="Convertible Bonds" className="bond-card">
        <Paragraph>
          Convertible bonds can be converted into another asset, such as NFTs or cryptocurrency, at the option of the
          bondholder. This feature allows bondholders to benefit from potential increases in the value of the underlying
          asset, while still receiving regular interest payments.
        </Paragraph>
        <Divider />
        <Paragraph>
          Convertible bonds are popular among investors who are looking for a way to participate in the potential upside
          of an asset, while still receiving a fixed income stream. However, convertible bonds are generally issued with
          a lower interest rate than non-convertible bonds, to compensate for the added flexibility.
        </Paragraph>
      </Card>
      <Divider />
      <Card title="Zero-Coupon Bonds" className="bond-card">
        <Paragraph>
          Zero-coupon bonds do not pay periodic interest, but instead pay a single lump sum at maturity. This means that
          the bondholder will receive a larger payout at the end of the bond's term, but will not receive any income
          during the life of the bond.
        </Paragraph>
        <Divider />
        <Paragraph>
          Zero-coupon bonds are popular among investors who are looking to receive a lump sum payout at a specific point
          in time, such as for retirement or a child's education. They are also useful for investors who want to lock in
          a fixed rate of return for a specific term, without having to worry about changes in interest rates. However,
          because zero-coupon bonds do not provide any income during the life of the bond, they can be riskier than
          other types of bonds.
        </Paragraph>
      </Card>
      <Divider />
      <Card title="Credit-Linked Notes" className="bond-card">
        <Paragraph>
          Credit-linked notes are bonds that are tied to the creditworthiness of a particular issuer. If the issuer
          defaults on their debt, the value of the bond is reduced or eliminated. Credit-linked notes are typically
          issued with a higher interest rate than other types of bonds, to compensate for the increased risk.
        </Paragraph>
        <Divider />
        <Paragraph>
          Credit-linked notes are popular among investors who are looking for a way to participate in the potential
          upside of a specific issuer, while still receiving a fixed income stream. However, credit-linked notes are
          generally considered riskier than other types of bonds, as the value of the bond is tied to the
          creditworthiness of a single issuer.
        </Paragraph>
      </Card>
      <Divider />
      <Card title="Floating-Rate Bonds" className="bond-card">
        <Paragraph>
          Floating-rate bonds pay a variable rate of interest that is tied to a benchmark interest rate, such as LIBOR.
          This means that the interest payments will fluctuate over time, depending on changes in the benchmark rate.
          Floating-rate bonds are typically issued with a maturity date, at which point the bondholder will receive the
          face value of the bond.
        </Paragraph>
        <Divider />
        <Paragraph>
          Floating-rate bonds are popular among investors who are looking to hedge against inflation, as the interest
          rate will generally rise as inflation increases. However, floating-rate bonds can be riskier than fixed-rate
          bonds, as the interest rate is subject to market fluctuations.
        </Paragraph>
      </Card>
      <Divider />
      <Card title="Perpetual Bonds" className="bond-card">
        <Paragraph>
          Perpetual bonds are bonds that do not have a maturity date, and pay a fixed rate of interest in perpetuity.
          This means that the bondholder will receive a regular income stream for as long as they hold the bond, but
          will not receive the face value of the bond at a specific point in time.
        </Paragraph>
        <Divider />
        <Paragraph>
          Perpetual bonds are popular among investors who are looking for a stable income stream over the long term, as
          the fixed interest rate provides a predictable return. However, because perpetual bonds do not have a maturity
          date, they can be riskier than other types of bonds, as the issuer may not have the ability to redeem the bond
          if circumstances change.
        </Paragraph>
      </Card>
      <Divider />
      <Paragraph className="disclaimer">
        This information is provided for educational purposes only and does not constitute investment advice. Investors
        should always do their own research and consult with a financial advisor before making any investment decisions.
      </Paragraph>
      <Divider />
    </div>
  );
};

export default InfoPage;
