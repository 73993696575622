import { ethers } from "ethers";

export const bondDetails = [
  {
    id: 1,
    name: "SmarTrib3 Fixed-Rate-721 Bond",
    type: "Fixed-Rate-721",
    issuer: "SmarTrib3",
    faceValue: ethers.utils.parseUnits("10000", "wei"),
    issuePrice: ethers.utils.parseUnits("9500", "wei"),
    interestRate: 5,
    maturity: 365,
    redemptionFee: 0,
    conversionAsset: "",
    conversionRate: 0,
    tokenType: "ERC721",
    // tokenAddress: FixedRateBond.address,
    tokenURI: "https://example.com/api/bond/1.json",
    issueDate: new Date(Date.now() - Math.floor(Math.random() * 7776000000) + 2592000000).getTime() // Last 3 months
  },
  {
    id: 2,
    name: "SmarTrib3 Fixed-Rate-1155 Bond",
    type: "Fixed-Rate-1155",
    issuer: "SmarTrib3",
    faceValue: ethers.utils.parseUnits("10000", "wei"),
    issuePrice: ethers.utils.parseUnits("9500", "wei"),
    interestRate: 5,
    maturity: 365,
    redemptionFee: 0,
    conversionAsset: "",
    conversionRate: 0,
    tokenType: "ERC1155",
    // tokenAddress: FixedRateBond.address,
    tokenURI: "https://example.com/api/bond/1.json",
    issueDate: new Date(Date.now() - Math.floor(Math.random() * 7776000000) + 2592000000).getTime() // Last 3 months
  },
  {
    id: 3,
    name: "SmarTrib3 Floating-Rate Bond",
    type: "Floating-Rate",
    issuer: "SmarTrib3",
    faceValue: ethers.utils.parseUnits("10000", "wei"),
    issuePrice: ethers.utils.parseUnits("9000", "wei"),
    interestRate: "+- " + 7,
    maturity: 365,
    redemptionFee: 0,
    conversionAsset: "",
    conversionRate: 0,
    tokenType: "ERC721",
    // tokenAddress: ZeroCouponBond.address,
    tokenURI: "https://example.com/api/bond/4.json",
    issueDate: new Date(Date.now() - Math.floor(Math.random() * 7776000000) + 2592000000).getTime() // Last 3 months
  },
  {
    id: 4,
    name: "SmarTrib3 Callable Bond",
    type: "Callable",
    issuer: "SmarTrib3",
    faceValue: ethers.utils.parseUnits("10000", "wei"),
    issuePrice: ethers.utils.parseUnits("8800", "wei"),
    interestRate: 12,
    maturity: 365,
    redemptionFee: 2000,
    conversionAsset: "",
    conversionRate: 0,
    tokenType: "ERC1155",
    // tokenAddress: CallableBond.address,
    tokenURI: "",
    issueDate: new Date(Date.now() - Math.floor(Math.random() * 7776000000) + 2592000000).getTime() // Last 3 months
  },
  {
    id: 5,
    name: "SmarTrib3 Convertible Bond",
    type: "Convertible",
    issuer: "SmarTrib3",
    faceValue: ethers.utils.parseUnits("10000", "wei"),
    issuePrice: ethers.utils.parseUnits("8000", "wei"),
    interestRate: 20,
    maturity: 365,
    redemptionFee: 0,
    // conversionAsset: ERC20.address,
    conversionRate: ethers.utils.parseUnits("2", "wei"),
    tokenType: "ERC721",
    // tokenAddress: ConvertibleBond.address,
    tokenURI: "https://example.com/api/bond/3.json",
    issueDate: new Date(Date.now() - Math.floor(Math.random() * 7776000000) + 2592000000).getTime() // Last 3 months
  },
  {
    id: 6,
    name: "SmarTrib3 Zero-Coupon Bond",
    type: "Zero-Coupon",
    issuer: "SmarTrib3",
    faceValue: ethers.utils.parseUnits("10000", "wei"),
    issuePrice: ethers.utils.parseUnits("9000", "wei"),
    interestRate: 10,
    maturity: 365,
    redemptionFee: 0,
    conversionAsset: "",
    conversionRate: 0,
    tokenType: "ERC721",
    // tokenAddress: ZeroCouponBond.address,
    tokenURI: "https://example.com/api/bond/4.json",
    issueDate: new Date(Date.now() - Math.floor(Math.random() * 7776000000) + 2592000000).getTime() // Last 3 months
  },
  {
    id: 7,
    name: "SmarTrib3 Credit-Linked Note",
    type: "Credit-Linked",
    issuer: "SmarTrib3",
    faceValue: ethers.utils.parseUnits("10000", "wei"),
    issuePrice: ethers.utils.parseUnits("9000", "wei"),
    interestRate: 10,
    maturity: 365,
    redemptionFee: 0,
    conversionAsset: "",
    conversionRate: 0,
    tokenType: "ERC721",
    // tokenAddress: ZeroCouponBond.address,
    tokenURI: "https://example.com/api/bond/4.json",
    issueDate: new Date(Date.now() - Math.floor(Math.random() * 7776000000) + 2592000000).getTime() // Last 3 months
  },
  {
    id: 8,
    name: "SmarTrib3 Perpetual Bond",
    type: "Perpetual",
    issuer: "SmarTrib3",
    faceValue: ethers.utils.parseUnits("10000", "wei"),
    issuePrice: ethers.utils.parseUnits("9000", "wei"),
    interestRate: 3,
    maturity: 365,
    redemptionFee: 0,
    conversionAsset: "",
    conversionRate: 0,
    tokenType: "ERC721",
    // tokenAddress: ZeroCouponBond.address,
    tokenURI: "https://example.com/api/bond/4.json",
    issueDate: new Date(Date.now() - Math.floor(Math.random() * 7776000000) + 2592000000).getTime() // Last 3 months
  },
];

export const mockDetails = {
  data: {
    bonds: bondDetails,
    bondCount: 7
  },
  isLoading: false,
  error: false,
  
};
