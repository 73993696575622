import { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ethers } from "ethers";
import { Row, Col, Card, Select, Modal, Form, Input, Button, notification, Divider } from "antd";
// import { GET_BONDS } from "../queries/bonds";
import { bondDetails, mockDetails } from "../constants/BondDetails";

const { Option } = Select;

const Marketplace = ({ contracts }) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedToken, setSelectedToken] = useState(null);
  const [selectedBond, setSelectedBond] = useState(null);
  const [tokenURI, setTokenURI] = useState("");
  const [faceValue, setFaceValue] = useState("");
  const [issuePrice, setIssuePrice] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [maturity, setMaturity] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  // const {
  //   loading: queryLoading,
  //   error: queryError,
  //   data,
  // } = useQuery(GET_BONDS, {
  //   variables: {
  //     skip: (selectedPage - 1) * pageSize,
  //     first: pageSize,
  //   },
  // });
  const {
    isLoading,
    error,
    data
  } = mockDetails;

  useEffect(() => {
    setSelectedBond(null);
    setSelectedToken(null);
  }, [modalVisible]);

  const calculateMaturityDate = (issueDate, days) => {
    const oneDayInSeconds = 24 * 60 * 60;
    const issueDateInSeconds = issueDate / 1000;
    const maturityDateInSeconds = issueDateInSeconds + (days * oneDayInSeconds);
    return new Date(maturityDateInSeconds * 1000);
  };

  const createBond = async () => {
    setLoading(true);
    try {
      const tx = await contracts.marketplace.createBond(
        selectedToken,
        tokenURI,
        ethers.utils.parseEther(faceValue),
        ethers.utils.parseEther(issuePrice),
        interestRate * 100,
        maturity,
      );
      await tx.wait();
      setModalVisible(false);
      notification.success({
        message: "Bond Created",
        description: "Your bond has been created successfully.",
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Bond Creation Failed",
        description: "An error occurred while creating your bond. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  // if (queryLoading) return <p>Loading...</p>;
  // if (queryError) return <p>Error :(</p>;

  const { bonds } = data;

  return (
    <div className="marketplace-container">
      <h1>Bonds Marketplace</h1>
      <p>Buy and sell bonds on the decentralized marketplace.</p>
      <Row gutter={[16, 16]}>
        {bonds.map(bond => (
          <Col span={8} key={bond.id}>
            <Card
              title={bond.name}
              extra={
                <Button type="link" onClick={() => setSelectedBond(bond)}>
                  View Details
                </Button>
              }
            >
              <p>
                <strong>Issuer:</strong> {bond.issuer}
              </p>
              <p>
                <strong>Type:</strong> {bond.type}
              </p>
              <p>
                <strong>Face Value:</strong> {bond.faceValue.toString()/1000} ETH
              </p>
              <p>
                <strong>Issue Price:</strong> {bond.issuePrice.toString()/4000} ETH
              </p>
              <p>
                <strong>Interest Rate:</strong> {bond.interestRate}%
              </p>
              <p>
                <strong>Maturity:</strong> {new Date(calculateMaturityDate(bond.issueDate, bond.maturity)).toLocaleDateString()}
              </p>
              <p>
                <strong>Redemption Fee:</strong> {ethers.utils.formatEther(bond.redemptionFee)} ETH
              </p>
              {bond.conversionAsset && (
                <p>
                  <strong>Conversion Asset:</strong> {bond.conversionAsset}
                </p>
              )}
              {bond.conversionRate && (
                <p>
                  <strong>Conversion Rate:</strong> {ethers.utils.formatEther(bond.conversionRate)}
                </p>
              )}
              <Divider/>
              <Button>Buy Now!</Button>
            </Card>
          </Col>
        ))}
      </Row>
      <div className="pagination-container">
        <Select value={pageSize} onChange={value => setPageSize(value)} style={{ marginRight: 16 }}>
          <Option value={1}>1 per page</Option>
          <Option value={5}>5 per page</Option>
          <Option value={12}>12 per page</Option>
          <Option value={24}>24 per page</Option>
          <Option value={48}>48 per page</Option>
        </Select>
        <Select value={selectedPage} onChange={value => setSelectedPage(parseInt(value))}>
          {[...Array(Math.ceil(data.bondCount / pageSize)).keys()].map(page => (
            <Option key={page + 1} value={page + 1}>
              Page {page + 1}
            </Option>
          ))}
        </Select>
      </div>
      <Modal
        title="Create Bond"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={createBond}
            disabled={!selectedToken || !tokenURI || !faceValue || !issuePrice || !interestRate || !maturity}
          >
            Create Bond
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Token Type">
            <Select value={selectedToken} onChange={value => setSelectedToken(value)}>
              <Option value="Fixed-Rate-721">Fixed Rate ERC721 Bond</Option>
              <Option value="Fixed-Rate-1155">Fixed Rate ERC1155 Bond</Option>
              <Option value="Floating-Rate">Floating-Rate Bond</Option>
              <Option value="Callable">Callable Bond</Option>
              <Option value="Convertible">Convertible Bond</Option>
              <Option value="Zero-Coupon">Zero-Coupon Bond</Option>
              <Option value="Credit-Linked">Credit-Linked Note</Option>
              <Option value="Perpetual">Perpetual Bond</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Token URI">
            <Input value={tokenURI} onChange={e => setTokenURI(e.target.value)} />
          </Form.Item>
          <Form.Item label="Face Value (ETH)">
            <Input value={faceValue} onChange={e => setFaceValue(e.target.value)} />
          </Form.Item>
          <Form.Item label="Issue Price (ETH)">
            <Input value={issuePrice} onChange={e => setIssuePrice(e.target.value)} />
          </Form.Item>
          <Form.Item label="Interest Rate (%)">
            <Input value={interestRate} onChange={e => setInterestRate(e.target.value)} />
          </Form.Item>
          <Form.Item label="Maturity (Unix Timestamp)">
            <Input value={maturity} onChange={e => setMaturity(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Marketplace;
