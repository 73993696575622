import React from "react";
import "../themes/Info.css";
import { Card } from "antd";

const About = () => {
  const theme = window.localStorage.getItem("theme");
  return (
    <div className={`info-container ${theme}`}>
      <h1 className="info-title">SMARTRIB3 TECHNOLOGY</h1>
      <Card>
        <p className="info-text">
          SMARTRIB3 TECHNOLOGY is a blockchain technology company that aims to bring the benefits of blockchain to
          Native American tribes and communities. Our goal is to help tribes and communities leverage the power of
          blockchain to overcome challenges related to land rights, economic development, and cultural preservation.
        </p>
      </Card>

      <h2 className="info-subtitle">Mission</h2>
      <Card>
        <p className="info-text">
          Our mission is to empower Native American tribes and communities with blockchain technology, enabling them to
          better manage and protect their land, increase economic opportunities, and preserve their cultural heritage.
        </p>
      </Card>

      <h2 className="info-subtitle">Products and Services</h2>
      <Card>
        <p className="info-text">
          We offer a range of products and services to help tribes and communities adopt and use blockchain technology,
          including:
        </p>
        <ul className="info-list">
          <li>
            Blockchain-based Fractal land management: a secure and immutable platform for managing land ownership and
            rights, enabling tribes and communities to protect their land from encroachment and support land claims.
            This is a solution and continuation of Elouise Cobell's work and settlement of the Cobell v. Salazar
            lawsuit, which sought to address historical mismanagement of Indian trust funds and lands.
          </li>
          <li>
            Secure and efficient payment and transaction system: a platform for secure and efficient transactions,
            facilitating peer-to-peer lending and crowdfunding, and enabling the creation of a Native American digital
            currency.
          </li>
          <li>
            Digital preservation platform: a secure platform for the preservation and sharing of traditional knowledge
            and art, enabling tribes and communities to protect and promote their cultural heritage.
          </li>
        </ul>
      </Card>

      <h2 className="info-subtitle">Team</h2>
      <Card>
        <p className="info-text">
          Our team consists of blockchain experts, software developers, and Native American advocates. We have a deep
          understanding of blockchain technology, its potential benefits, and the challenges faced by Native American
          tribes and communities. We are committed to working closely with tribes and communities to understand their
          specific needs and develop customized solutions that meet their unique requirements.
        </p>
      </Card>

      <h2 className="info-subtitle">Partnerships</h2>
      <Card>
        <p className="info-text">
          We are committed to building partnerships with Native American tribes, community organizations, and other
          stakeholders to ensure that our products and services are tailored to meet the specific needs of each
          community. We also work with other blockchain technology companies and organizations to share knowledge and
          best practices and promote the use of blockchain in Native American communities.
        </p>
      </Card>

      <h2 className="info-subtitle">Contact</h2>
      <Card>
        <p className="info-text">
          To learn more about SMARTRIB3 TECHNOLOGY and our products and services, please contact us at{" "}
          <a href="mailto:dhall@smartrib3.com" className="info-link">
            dhall@smartrib3.com
          </a>
          . We look forward to hearing from you and working together to bring the benefits of blockchain to Native
          American tribes and communities.
        </p>
      </Card>
    </div>
  );
};

export default About;
