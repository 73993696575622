import { useEffect, useState } from "react";
import { Select, Layout } from "antd";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { stackoverflowDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ReactMarkdown from "react-markdown";
import CallableBond from "../constants/CallableBond.md";
import ConvertibleBond from "../constants/ConvertibleBond.md";
import CreditLinkedNote from "../constants/CreditLinkedNote.md";
import FixedRateERC721Bond from "../constants/FixedRateERC721Bond.md";
import FixedRateERC1155Bond from "../constants/FixedRateERC1155Bond.md";
import FloatingRateBond from "../constants/FloatingRateBond.md";
import PerpetualBond from "../constants/PerpetualBond.md";
import ZeroCouponBond from "../constants/ZeroCouponBond.md";
import bondREADME from "../BOND.README.md";

const { Option } = Select;
const { Sider, Content } = Layout;

const contractDescriptions = {
  CallableBond: "Callable bond that allows the issuer to redeem the bond early under certain conditions.",
  ConvertibleBond: "Convertible bond that can be converted into another asset at the option of the bondholder.",
  CreditLinkedNote:
    "Bond that is tied to the creditworthiness of a particular issuer. If the issuer defaults on their debt, the value of the bond is reduced or eliminated.",
  FixedRateERC721Bond:
    "Fixed-rate bond that pays a fixed rate of interest throughout the life of the bond, represented as an ERC721 token.",
  FixedRateERC1155Bond:
    "Fixed-rate bond that pays a fixed rate of interest throughout the life of the bond, represented as an ERC1155 token.",
  FloatingRateBond:
    "Bond that pays a variable rate of interest that is tied to a benchmark interest rate, such as LIBOR.",
  PerpetualBond: "Bond that does not have a maturity date and pays a fixed rate of interest in perpetuity.",
  ZeroCouponBond: "Bond that does not pay periodic interest, but instead pays a single lump sum at maturity.",
};

const ContractPage = ({ theme }) => {
  const [selectedContract, setSelectedContract] = useState("BOND.README");
  const [contractMarkdown, setContractMarkdown] = useState("");

  useEffect(() => {
    const fetchContractMarkdown = async () => {
      let markdown = "";
      if (selectedContract === "BOND.README") {
        const response = await fetch(bondREADME);
        markdown = await response.text();
      } else if (selectedContract === "CallableBond") {
        const response = await fetch(CallableBond);
        markdown = await response.text();
      } else if (selectedContract === "ConvertibleBond") {
        const response = await fetch(ConvertibleBond);
        markdown = await response.text();
      } else if (selectedContract === "CreditLinkedNote") {
        const response = await fetch(CreditLinkedNote);
        markdown = await response.text();
      } else if (selectedContract === "FixedRateERC721Bond") {
        const response = await fetch(FixedRateERC721Bond);
        markdown = await response.text();
      } else if (selectedContract === "FixedRateERC1155Bond") {
        const response = await fetch(FixedRateERC1155Bond);
        markdown = await response.text();
      } else if (selectedContract === "FloatingRateBond") {
        const response = await fetch(FloatingRateBond);
        markdown = await response.text();
      } else if (selectedContract === "PerpetualBond") {
        const response = await fetch(PerpetualBond);
        markdown = await response.text();
      } else if (selectedContract === "ZeroCouponBond") {
        const response = await fetch(ZeroCouponBond);
        markdown = await response.text();
      }
      setContractMarkdown(markdown);
    };
    fetchContractMarkdown();
  }, [selectedContract]);

  const contractOptions = [
    "CallableBond",
    "ConvertibleBond",
    "CreditLinkedNote",
    "FixedRateERC721Bond",
    "FixedRateERC1155Bond",
    "FloatingRateBond",
    "PerpetualBond",
    "ZeroCouponBond",
  ];
  return (
    <>
      <Layout>
        <Sider theme={theme} style={{ position: "sticky" }}>
          <div style={{ position: "sticky", top: 0 }}>
            <Select value={selectedContract} onChange={value => setSelectedContract(value)} style={{ width: "100%" }}>
              <Option value="BOND.README">Bond Market README</Option>
              {contractOptions.map(option => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
            {selectedContract !== "BOND.README" && (
              <p style={{ textAlign: "left" }}>{contractDescriptions[selectedContract]}</p>
            )}
          </div>
        </Sider>
        <Content style={{ padding: "24px", textAlign: "left" }}>
          {selectedContract === "BOND.README" ? (
            <ReactMarkdown>{contractMarkdown}</ReactMarkdown>
          ) : (
            <SyntaxHighlighter style={stackoverflowDark}>{contractMarkdown}</SyntaxHighlighter>
          )}
        </Content>
      </Layout>
    </>
  );
  // };
};

export default ContractPage;
