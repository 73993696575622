import { Card, Col, Row } from "antd";
import React from "react";
import About from "./About";
/**
 * web3 props can be passed from '../App.jsx' into your local view component for use
 * @param {*} yourLocalBalance balance on current network
 * @param {*} readContracts contracts from current chain already pre-loaded using ethers contract module. More here https://docs.ethers.io/v5/api/contract/contract/
 * @returns react component
 **/
function Home({ yourLocalBalance, readContracts, theme }) {
  return (
    <div>
      <div className="bonds-container">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card className="bonds-card" title="Empowering Native American Communities">
              <p>
                Welcome to SmarTrib3, a premier financial institution committed to empowering Native American
                communities through equitable and innovative banking solutions. Our mission is to bridge the financial
                gap and drive economic growth in Native American territories by providing secure, transparent, and
                accessible investment opportunities.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card className="bonds-card" title="Exclusive Bond Offerings">
              <p>
                As a leader in the industry, SmarTrib3 has designed exclusive bond offerings tailored to the needs of
                Native American tribesmen. Our carefully crafted portfolio of bonds emphasizes stability, competitive
                interest rates, and long-term growth potential, ensuring a powerful tool for wealth generation and
                financial security.
              </p>
            </Card>
          </Col>
        </Row>
      </div>
    <About />
    </div>
  );
}

export default Home;
